
















































import Vue from "vue";
import Footer from "@/components/back-footer.vue";
export default Vue.extend({
  data: function () {
    return {
      groups: [
        {
          group: 1,
          bg: "rgba(254, 212, 75, 1)",
          selected: true,
          values: [
            {
              value: "Abundance",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Empathy",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: true,
            },
            {
              value: "Humility",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Acceptance",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Appreciation",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Boldness",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
          ],
          class: "yellow",
        },
        {
          group: 2,
          bg: "rgba(237, 171, 176, 1)",
          selected: false,
          values: [
            {
              value: "Ambition",
              selected: false,
              grouped: true,
              groupClass: "pale",
              group: 2,
              isPrimary: false,
            },
            {
              value: "Benevolence",
              selected: false,
              grouped: true,
              groupClass: "pale",
              group: 2,
              isPrimary: true,
            },
            {
              value: "Selflessness",
              selected: false,
              grouped: true,
              groupClass: "pale",
              group: 2,
              isPrimary: false,
            },
            {
              value: "Quality",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Responsibility",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
            {
              value: "Teamwork",
              selected: false,
              grouped: true,
              groupClass: "yellow",
              group: 1,
              isPrimary: false,
            },
          ],
          class: "pale",
        },
        {
          group: 3,
          bg: "rgba(14, 158, 146, 1)",
          selected: false,
          values: [
            {
              value: "Compassion",
              selected: false,
              grouped: true,
              groupClass: "green",
              group: 3,
              isPrimary: false,
            },
            {
              value: "Cheerfulness",
              selected: false,
              grouped: true,
              groupClass: "green",
              group: 3,
              isPrimary: true,
            },
            {
              value: "Inspiration",
              selected: false,
              grouped: true,
              groupClass: "green",
              group: 3,
              isPrimary: false,
            },
          ],
          class: "green",
        },
        {
          group: 4,
          bg: "rgba(114, 144, 242, 1)",
          selected: false,
          values: [
            {
              value: "Curiosity",
              selected: false,
              grouped: true,
              groupClass: "blue",
              group: 4,
              isPrimary: true,
            },
            {
              value: "Leadership",
              selected: false,
              grouped: true,
              groupClass: "blue",
              group: 4,
              isPrimary: false,
            },
          ],
          class: "blue",
        },
        {
          group: 5,
          bg: "rgba(245, 108, 119, 1)",
          selected: false,
          values: [
            {
              value: "Fairness",
              selected: false,
              grouped: true,
              groupClass: "red",
              group: 5,
              isPrimary: false,
            },
            {
              value: "Generosity",
              selected: false,
              grouped: true,
              groupClass: "red",
              group: 5,
              isPrimary: false,
            },
            {
              value: "Uniqueness",
              selected: false,
              grouped: true,
              groupClass: "red",
              group: 5,
              isPrimary: true,
            },
          ],
          class: "red",
        },
      ],
    };
  },
  components: {
    Footer,
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("SetLogoBottomStyle", { color: "light" });
  },
  methods: {
    SelectGroup: function (
      selected: boolean,
      groupClass: string,
      index: number
    ) {
      if (!selected) {
for(let i=0;i<this.groups.length;i++){
this.groups[i].selected = false;
}
this.groups[index].selected = true;
        const dash = this.$refs["nav-dash"] as HTMLElement;
        dash.className = `navigating-dash ${groupClass}`;
        if (index === 0) {
          dash.style.left = "0";
        } else if (index === 4) {
          dash.style.left = "99%";
        } else {
          dash.style.left = index * 25 - 0.8 + "%";
        }
      } else {
        return;
      }
    },
    TogglePrimary(groupIndex: number, valIndex: number) {
      if (
        !this.$store.state.values.groups[groupIndex].values[valIndex].isPrimary
      ) {
        this.$store.dispatch("MakePrimaryValue", {
          groupIndex: groupIndex,
          index: valIndex,
        });
      }
    },
  },
});
